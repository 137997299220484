import { TfiEmail } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";

import ULogo from "../../images/ULogo.png";

import "./footer.scss";

const Footer = () => {
  const navigate = useNavigate();

  return <div className="container my-5">
    <footer>
      <div className="container p-4">
        <div className="row">
          <div className="col-lg-3 col-md-6 mb-4">
            <img
              className="logo"
              src={ULogo}
              onClick={() => navigate("/home")}
            />
          </div>
          <div className="col-lg-3 col-md-6 mb-4">
            <h6 className="mb-3">Contacts</h6>
            <div className="email">
              <a href="mailto:hello@qkira.com">
              <TfiEmail /> hello@qkira.com
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 mb-4">
            <h6>About the Application</h6>
            <p>
              We envision a world where
              scientific discoveries are easily accessible, understandable, and
              shared in a captivating video format, fostering collaboration,
              inspiring curiosity, and empowering individuals to make a positive
              impact on society. Together, we are shaping the future of research
              communication and democratizing the pursuit of knowledge for the
              betterment of humanity.
            </p>
            <h6>Mission</h6>
            <p>
              To empower researchers, scholars, and knowledge seekers worldwide
              by revolutionizing the way scientific knowledge is accessed,
              understood, and shared.
            </p>
            <h6>Purpose</h6>
            <p>
              To unlock the power of scientific knowledge through engaging
              videos
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>;
};

export default Footer;
