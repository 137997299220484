import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { createAvatar } from "@dicebear/core";
import { initials } from "@dicebear/collection";
import {
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ImHome } from "react-icons/im";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineVideoCameraAdd } from "react-icons/ai";
import {
  RiAdminLine,
  RiPlayList2Fill,
  RiLoginCircleLine,
  RiLogoutCircleRLine,
} from "react-icons/ri";

import Search from "../Search";
import Logo from "../../images/ULogo.png";
import AddNewRecord from "../AddNewRecord";
import { AppContext } from "../../store/AppContext";
import { getUserInfo, getCategories, logout } from "../../Services";

import "./header.scss";

const Header = (props) => {
  const {
    isLoggedIn,
    setLoggedIn,
    userDetails,
    setUserDetails,
    categories,
    setCategories,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [activePath, setPath] = useState("/home");
  const [isAddNewOpen, setAddNewOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isToggleOpen, setToggleOpen] = useState(false);

  const url = window.location.pathname;
  const toggle = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const headerList = [
    {
      name: "Add New Record",
      path: null,
      isAdmin: false,
      logo: AiOutlineVideoCameraAdd,
    },
    {
      name: "My Videos",
      path: "/videos",
      isAdmin: false,
      logo: RiPlayList2Fill,
    },
    {
      name: "Admin",
      path: "/admin",
      isAdmin: true,
      logo: RiAdminLine,
    },
    {
      name: "SignOut",
      path: "/",
      isAdmin: false,
      logo: RiLogoutCircleRLine,
    },
  ];

  useEffect(() => {
    if (isLoggedIn && userDetails === null) {
      (async () => {
        let users = await getUserInfo();
        setUserDetails(users);
        generateAvatar(users.email);
      })();
    } else {
      userDetails?.email && generateAvatar(userDetails.email);
    }

    (async () => {
      const response = await getCategories();
      setCategories(response.categoryNames.sort());
    })();

    window.addEventListener("resize", () => setToggleOpen(false));
    return () => {
      window.removeEventListener("resize", () => setToggleOpen(false));
    };
  }, []);

  useEffect(() => {
    userDetails?.email && generateAvatar(userDetails.email);
  }, [dropdownOpen]);

  const generateAvatar = (email) => {
    let avatar = createAvatar(initials, {
      seed: email,
    });

    var container = document.getElementById("avatar-element");
    var dropdown = document.getElementById("avatar-element-drop-down");
    if (dropdown) dropdown.innerHTML = avatar.toJson().svg;
    if (container) container.innerHTML = avatar.toJson().svg;
  };

  const isPathActive = (path) => {
    return path === activePath;
  };

  const onNavigate = async (path, name) => {
    if (name === "SignOut") {
      const response = await logout(userDetails.email);
      if (response.success) {
        setLoggedIn(false);
        setUserDetails(null);
      }
    }
    setPath(path);
  };

  const onAddNewClick = () => {
    setAddNewOpen(true);
  };

  const onHeaderNavigatorClick = (path, name) => {
    setToggleOpen(!isToggleOpen);
    onNavigate(path, name);
  }

  const getOptions = () => {
    let options = [];

    if (userDetails?.email) {
      options.push(
        <div className="user-details">
          <div id="avatar-element-drop-down" />
          <div className="email">{userDetails.email}</div>
        </div>
      );
    }

    headerList.forEach((item) => {
      if (item.isAdmin && userDetails?.role === "ADMIN") {
        options.push(
          <DropdownItem>
            <Link
              to={item.path}
              onClick={() => onHeaderNavigatorClick(item.path, item.name)}
            >
              <item.logo />
              {item.name}
            </Link>
          </DropdownItem>
        );
      } else {
        if (item.path === null) {
          options.push(
            <DropdownItem>
              <a onClick={onAddNewClick}>
                <item.logo />
                {item.name}
              </a>
            </DropdownItem>
          );
        } else {
          !item.isAdmin && options.push(
            <DropdownItem>
              <Link
                to={item.path}
                onClick={() => onNavigate(item.path, item.name)}
              >
                <item.logo />
                {item.name}
              </Link>
            </DropdownItem>
          );
        }
      }
    });

    return options;
  };

  useEffect(() => {
    setPath(url);
  }, [url]);

  const onLogoClick = () => {
    onNavigate("/home");
    navigate("/home");
  };

  const getNavItems = (isLoggedIn) => {
    if (isLoggedIn) {
      let userOptionList = [];
      headerList.forEach((item) => {
        if (item.isAdmin && userDetails?.role === "ADMIN") {
          userOptionList.push(
            <NavItem>
              <NavLink>
                <Link
                  to={item.path}
                  onClick={() => onNavigate(item.path, item.name)}
                >
                  <item.logo />
                  {item.name}
                </Link>
              </NavLink>
            </NavItem>
          );
        } else {
          if (item.path === null) {
            userOptionList.push(
              <NavItem>
                <NavLink>
                  <a onClick={onAddNewClick}>
                    <item.logo />
                    {item.name}
                  </a>
                </NavLink>
              </NavItem>
            );
          } else {
            !item.isAdmin && userOptionList.push(
              <NavItem>
                <NavLink>
                  <Link
                    to={item.path}
                    onClick={() => onNavigate(item.path, item.name)}
                  >
                    <item.logo />
                    {item.name}
                  </Link>
                </NavLink>
              </NavItem>
            );
          }
        }
      });

      return (
        <>
          <div className="user-details-minview">
            <div id="avatar-element" />
            <div className="email">{userDetails?.email}</div>
          </div>
          {userOptionList}
        </>
      );
    } else {
      return (
        <>
          <NavItem>
            {isPathActive("/category") ? (
              <NavLink>
                <Link to="/home">
                  <ImHome />
                  Home
                </Link>
              </NavLink>
            ) : null}
            <NavLink className={isPathActive("/login") ? "active" : ""}>
              <Link to="/login">
                <RiLoginCircleLine />
                Login
              </Link>
            </NavLink>
          </NavItem>
        </>
      );
    }
  };

  const onNavToggleClick = () => {
    setTimeout(() => generateAvatar(userDetails?.email), 0);
    setToggleOpen(!isToggleOpen);
  };

  return (
    <div>
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <a class="navbar-brand" onClick={onLogoClick}>
          <img src={Logo} onClick={onLogoClick} />
        </a>
        <Search category={categories} />
        <NavItem>
          <NavLink>
            <a
              href="https://www.dropbox.com/request/2sj5ZcZ8WceaGDH16hqx"
              target="_blank"
              rel="noopener noreferrer"
              className="dropbox-link"
            >
              Share your research clip with us
            </a>
          </NavLink>
        </NavItem>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="custom-toggle-icon">
            <GiHamburgerMenu onClick={onNavToggleClick} />
          </span>
        </button>
        <div
          class={`collapse navbar-collapse ${isToggleOpen && "show"}`}
          id="navbarSupportedContent"
        >
          {isToggleOpen ? (
            <ul class="navbar-nav mr-auto">{getNavItems(isLoggedIn)}</ul>
          ) : (
            <ul class="navbar-nav">
              {isLoggedIn && (
                <>
                  <NavItem>
                    <NavLink>
                      <Dropdown
                        className="user-dropdown"
                        isOpen={dropdownOpen}
                        toggle={toggle}
                      >
                        <DropdownToggle caret={false}>
                          <div id="avatar-element" />
                        </DropdownToggle>
                        <DropdownMenu className="header-dropdown">
                          {getOptions()}
                        </DropdownMenu>
                      </Dropdown>
                    </NavLink>
                  </NavItem>
                </>
              )}
              {!isLoggedIn && (
                <>
                  <NavItem>
                    <NavLink className={isPathActive("/login") ? "active" : ""}>
                      <Link to="/login">
                        <RiLoginCircleLine />
                        Login
                      </Link>
                    </NavLink>
                  </NavItem>
                </>
              )}
            </ul>
          )}
        </div>
      </nav>
      {isAddNewOpen && <AddNewRecord close={() => setAddNewOpen(false)} />}
      {props.children}
    </div>
  );
};

export default Header;
