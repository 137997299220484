import { memo, useEffect, useState } from "react";
import { getPublishedVideos } from "../../Services";
import InfiniteScroll from "react-infinite-scroll-component";

import "./relatedVideos.scss";
import { Spinner } from "reactstrap";

const RelatedVideos = memo(({ category, selectedVideo, selectVideo }) => {
  const [relatedVideos, setRelatedVideos] = useState([]);
  const [indexKey, setIndexKey] = useState(null);
  const [loader, setLoader] = useState(false)

  async function fetchRelevantVideos(category, isReset = false) {
    let loadedVideos = isReset ? [] : [...relatedVideos];
    setLoader(true)
    const response = await getPublishedVideos(category, 9, indexKey);
    const relevantVideos = response?.items?.filter(
      (video) => video.key !== selectedVideo
    );
    setRelatedVideos([...loadedVideos, ...relevantVideos]);
    if (response.lastEvaluatedKey) {
      setIndexKey(response.lastEvaluatedKey?.key);
    } else {
      setIndexKey(null);
    }
    setLoader(false)
  }

  useEffect(() => {
    if (category && selectedVideo) {
      fetchRelevantVideos(category, true);
    }
  }, [category, selectedVideo]);

  useEffect(() => {
    if (!loader) {
      document.getElementsByClassName("video-container")[0].scrollIntoView();
    }
  }, [loader]);

  return (
    <div className="related-videos">
      <div className="title">Related Videos</div>
      {loader ? <div style={{ height: "60vh", display: "flex", alignItems: "center", justifyContent: "center" }}><Spinner /></div> :
        <InfiniteScroll
          dataLength={relatedVideos.length}
          next={() => fetchRelevantVideos(category)}
          hasMore={Boolean(indexKey)}
          loader={<p>Loading...</p>}
          scrollableTarget="scrollable-div"
        >
          {relatedVideos.map((video) => (
            <div
              key={video.key}
              className="video-item"
              onClick={() => {
                 selectVideo(video, category);
                 setIndexKey(null);
              }}
            >
              <img
                width={150}
                height={150}
                alt={video.videoName}
                src={video.imageKey}
              />
              <div className="video-text-details">
                <h6>{video.videoName}</h6>
                {video?.uploaderDetails?.uploaderName && (
                  <p className="video-text">
                    {video.uploaderDetails.uploaderName}
                  </p>
                )}
                {video?.dateUploaded && (
                  <p className="video-text">
                    {new Date(video.dateUploaded).toLocaleString()}
                  </p>
                )}
              </div>
            </div>
          ))}
        </InfiniteScroll>}
    </div>
  );
});

export default RelatedVideos;
