import React, { useState, useEffect, useRef } from "react";
import { FaPlay, FaPause } from 'react-icons/fa';
import { BsArrowsFullscreen } from 'react-icons/bs'
import { ImVolumeMute2 } from 'react-icons/im'
import { VscUnmute } from 'react-icons/vsc'
import ReactPlayer from "react-player";
import { Button } from "reactstrap";
import { updateViewCount } from "../../Services";
import "./VideoPlayer.scss";
import { AiFillPropertySafety } from "react-icons/ai";

const CustomVideoPlayer = ({ videoUrl, videoId, categoryName }) => {
  const [playedPercentage, setPlayedPercentage] = useState(0);
  const [triggered10Percent, setTriggered10Percent] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const playerRef = useRef();

  const updateView = (key) => {
    console.log(`Updating views for video with key: ${key}`);
    updateViewCount({ key, categoryName });
  };

  const toggleMute = () => {
    setMute(!mute);
  };

  const [mute, setMute] = useState(false);
  const [volume, setVolume] = useState(0.8);

  const handleVolumeChange = (newVolume) => {
    setVolume(newVolume);
    if (playerRef.current) {
      playerRef.current.setVolume(newVolume);
      if (newVolume === 0) {
        setMute(true);
      } else {
        setMute(false);
      }
    }
  };


  const toggleFullscreen = () => {
    if (!isFullscreen) {
      if (playerRef.current) {
        playerRef.current.requestFullscreen(); // Enter fullscreen
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen(); // Exit fullscreen
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  useEffect(() => {
    if (playedPercentage >= 10 && !triggered10Percent) {
      updateView(videoId);
      setTriggered10Percent(true);
    }
  }, [playedPercentage, triggered10Percent, videoId]);

  const handleProgress = (progress) => {
    const percent = progress.played * 100;
    setPlayedPercentage(percent);
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const seek = (seconds) => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(currentTime + seconds, "seconds");
    }
  };

  const onSeekChange = (value) => {
    const fraction = value / 100;
    setPlayedPercentage(value);
    if (playerRef.current) {
      playerRef.current.seekTo(fraction);
    }
  };

  return (
    <div className="customVideoPlayer">
      <div onClick={togglePlayPause} className="video-container">
        <ReactPlayer
          ref={playerRef}
          url={videoUrl}
          controls={true}
          playing={isPlaying}
          volume={volume}
          muted={mute}
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload',
                PictureInPictureWindow: 'disablepictureinpicture'
              }
            }
          }}
          onProgress={handleProgress}
          className="react-player"
        />
      </div>
    </div>
  );
};

export default CustomVideoPlayer;

