import { AiFillEye, AiFillLike, AiOutlineEye } from "react-icons/ai";
import { useEffect, useState, useContext } from "react";
import { createAvatar } from "@dicebear/core";
import { HiCheckCircle } from "react-icons/hi";
import { AiOutlineLike } from "react-icons/ai";
import { initials } from "@dicebear/collection";
import { TiWarningOutline } from "react-icons/ti";
import { RiDeleteBin5Line } from "react-icons/ri";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { useNavigate } from 'react-router'

import VideoPlayer from "../VideoPlayer/VideoPlayer";
import LoginPromptPopup from "../LoginPrompt/LoginPrompt";
import Comments from "../Comments";
import RelatedVideos from "../RelatedVideos";
import { AppContext } from "../../store/AppContext";
import { AiOutlineShareAlt } from "react-icons/ai"; //added newly 
import {
  getVideo,
  deleteVideoByAdmin,
  deleteVideoByUser,
  getUserMetaVideoDetails,
  likeAction
} from "../../Services";

import "./detailsModal.scss";
import { Link } from "react-router-dom";

const DetailsModal = (props) => {
  const [data, setData] = useState(null);
  const [isError, setError] = useState(false);
  const [isUserLiked, setUserLiked] = useState(false);
  const [isLiked, setLiked] = useState(false);
  const { userDetails } = useContext(AppContext);
  const [viewCount, setViewCount] = useState(0);
  const [isLoginPopupOpen, setLoginPopupOpen] = useState(false);
  const navigate = useNavigate();
  const [showFullDescription, setShowFullDescription] = useState(false);

  const generateAvatar = (userName) => {
    let avatar = createAvatar(initials, {
      seed: userName,
    });
    var container = document.getElementById("author-avator");
    if (container) container.innerHTML = avatar.toJson().svg;
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };


  async function fetchData() {
    let response = await getVideo(props.id);
    if (response?.error) {
      setError(true);
    } else {
      setData(response);
      generateAvatar(response.uploader.uploaderName);
    }
  }

  useEffect(() => {
    fetchData();
  }, [props.id]);

  useEffect(() => {
    if (userDetails) {
      async function fetchLikes() {
        let response = await getUserMetaVideoDetails(props.id);
        if (response) {
          setLiked(response.videoLiked);
          setUserLiked(response.videoLiked);
        }
      }
      fetchLikes();
    }
  }, [props.id, userDetails]);

  const onDeleteHandler = async () => {
    let payload = {
      category: props.category,
      key: props.id,
    };
    let response = '';
    if (userDetails?.role === "ADMIN") {
      response = await deleteVideoByAdmin(payload);
    } else if (userDetails?.role === "USER") {
      response = await deleteVideoByUser(payload);
    }
    if (response) {
      navigate(0);
      props.onClose();
    }
  };


  const onLike = (isLike) => {
    if (userDetails) {
      let payload = {
        key: props.id,
        action: isLike,
      };
      setLiked(isLike);
      likeAction(payload);
    } else {
      setLoginPopupOpen(true)
    }
  };
  let loginPopupTimeout;
  const toggleLoginPopup = () => {
    setLoginPopupOpen(!isLoginPopupOpen);
    if (loginPopupTimeout) {
      clearTimeout(loginPopupTimeout);
    }
    loginPopupTimeout = setTimeout(() => {
      setLoginPopupOpen(false);
    }, 3000);
  };

  const getLikesCount = () => {
    let currentLikes = data.likes || 0;
    if (typeof currentLikes === 'string') {
      if (currentLikes.endsWith('K')) {
        currentLikes = parseFloat(currentLikes) * 1000;
      } else if (currentLikes.endsWith('M')) {
        currentLikes = parseFloat(currentLikes) * 1000000;
      } else {
        currentLikes = parseFloat(currentLikes);
      }
    }
    if (isLiked !== isUserLiked) {
      if (isLiked) {
        currentLikes++;
      } else {
        currentLikes--;
      }
    }
    if (currentLikes >= 1000000) {
      return (currentLikes / 1000000).toFixed(1) + 'M';
    } else if (currentLikes >= 1000) {
      return (currentLikes / 1000).toFixed(1) + 'K';
    } else {
      return currentLikes.toString();
    }
  };

  useEffect(() => {
    if (data?.viewCount) {
      setViewCount(data.viewCount);
    }
  }, [data?.viewCount])

  // async function updateViews(key) {
  //   let response = await updateViewCount(key);
  //   if (response?.error) {
  //     setError(true);
  //   } else {
  //     setViewCount(response.viewCount);
  //   }
  // }
  const handleShare = async () => {   //added newly 
    try {
      if (navigator.share) {
        await navigator.share({
          title: 'Check out this video!',
          text: data.title,
          url: window.location.href
        });
        console.log('Shared successfully');
      } else {
        console.log('Web Share API not supported');
        // Fallback for unsupported browsers
        // Implement your custom sharing logic here
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  }; // added newly till here
  return (
    <Modal isOpen={true} className="details-modal">
      <ModalHeader toggle={() => props.onClose()} />
      <ModalBody id="scrollable-div">
        {!isError && data && (
          <main className="video-details" >
            <section key={data.videoUrl} className="video-container" style={{ height: "75vh" }}>
              {data.videoUrl && (
                <VideoPlayer className='videoPlayer' videoUrl={data?.videoUrl} videoId={data?.key} categoryName={props.category} />
              )}
              <div className="title">{props.title}</div>
              <div className="category">{props.category}</div>
              <span className="category-container">
                <div className="author-details">
                  <div id="author-avator"></div>
                  <div className="user-details">
                    <div className="name">{data?.uploader?.uploaderName}</div>
                    <div className="date">
                      Uploaded: {new Date(props?.uploadedDate).toLocaleString()}
                    </div>
                  </div>
                </div>
                {props.isApproval === false && (
                  <div className="video-analytics">
                    <div className="icon">
                      {userDetails ? (
                        isLiked ? (
                          <AiFillLike onClick={() => onLike(false)} />
                        ) : (
                          <AiOutlineLike onClick={() => onLike(true)} />
                        )
                      ) : (
                        <div id="login-popup-target">
                          <AiOutlineLike onClick={toggleLoginPopup} />
                          <LoginPromptPopup
                            isOpen={isLoginPopupOpen}
                            target="login-popup-target"
                            toggle={toggleLoginPopup}
                          />
                        </div>
                      )}
                      {/* <div className="likes-count">{getLikesCount()}</div> */} 
                    </div>
                    <div className="icon">
                      <AiOutlineShareAlt onClick={handleShare} />
                      <div className="share">Share</div>
                    </div>
                    <div className="icon">
                      {/* {viewCount ? <AiFillEye /> : <AiOutlineEye />} */}
                      {/* <div className="view-count">{props.viewCount}</div> */}
                    </div>   
                  </div>
                )}
                {props?.isApproval === true && (
                  <div className="review">
                    <Button className="accept" onClick={props.onAccept}>
                      <HiCheckCircle />
                      <div>Accept</div>
                    </Button>
                    {/* <Button className="reject" onClick={props.onReject}>
                      <AiOutlineCloseCircle />
                      Decline
                    </Button> */}
                    {props.isDeletable && (
                      <Button className="reject" onClick={onDeleteHandler}>
                        <RiDeleteBin5Line />
                        Delete
                      </Button>
                    )}
                  </div>
                )}
              </span>
              <div className="description-box">
                <span className="researchId"><b>Research ID:</b><Link to={props.researchId} target="_blank">{props.researchId || '-'}</Link></span><br />
                {/* Toggle the rendering based on showFullDescription */}
                {showFullDescription ? props.content : props.content.substring(0, 200) + "  "}
                <span
                  className="read-more"
                  onClick={toggleDescription}
                  style={{ display: props.content.length > 200 ? "inline" : "none", cursor: "pointer" }}
                >
                  {showFullDescription ? " ...Read Less" : " ...Read More"}
                </span>
              </div>
              <Comments id={props.id} />
            </section>
            {!props?.hideRelaventVideos && <section className="other" style={{}} >
              <div style={{ height: "75vh", overflow: "auto", position: "sticky", top: "0px" }}>
                <RelatedVideos
                  category={props.category}
                  selectedVideo={props.id}
                  selectVideo={props.selectVideo}
                />
              </div>
            </section>}
          </main>
        )}
        {isError && (
          <div className="error">
            <TiWarningOutline />
            <div className="message">
              Something went wrong, please try again later.
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default DetailsModal;
