import React from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

const LoginPromptPopup = ({ isOpen, target, toggle }) => {
  return (
    <Popover placement="right" isOpen={isOpen} target={target} toggle={toggle}>
      <PopoverHeader>Login Required</PopoverHeader>
      <PopoverBody>Please login to leave a like or comment on this video.</PopoverBody>
    </Popover>
  );
};

export default LoginPromptPopup;
