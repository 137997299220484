import { useState, useContext } from "react";
import { toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input, Spinner } from "reactstrap";
import TermsAndConditions from "../../components/TermsAndConditions/TermsAndConditions";

import Logo from "../../images/ULogo.png";
import { signUp, activateAccount, resendVerificationCode } from "../../Services";
import { AppContext } from "../../store/AppContext";

import './signup.scss';

const SignUp = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [referrerCode, setReferrerCode] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [hasReferrerCode, setHasReferrerCode] = useState(false);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(30);
  const { setLoggedIn } = useContext(AppContext);


  const onChange = (value, updatedField) => {
    updatedField(value);
  };
  const isPasswordValid = () => {
    const hasCapitalLetter = /[A-Z]/.test(password);
    const hasSymbol = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const isLengthValid = password.length >= 7;

    return hasCapitalLetter && hasSymbol && hasNumber && isLengthValid;
  };

  const toggleTermsPopup = () => {
    setShowTermsPopup(!showTermsPopup);
  };

  const startResendTimer = () => {
    setResendDisabled(true); // Disable the resend button
    setResendTimer(30); // Reset the timer to 30 seconds

    const timerInterval = setInterval(() => {
      setResendTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timerInterval); // Clear the interval when timer reaches 0
      setResendDisabled(false); // Enable the resend button again
    }, 30000); // 30 seconds
  };

  const handleResendVerificationCode = async () => {
    if (!resendDisabled) {
      startResendTimer(); // Start the resend timer
      try {
        await resendVerificationCode(email); // Assuming this function sends the verification code
        toast.success("Verification code has been resent.");
      } catch (error) {
        toast.error("Error while resending verification code.");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const allowedDomains = ['yahoo.com', 'gmail.com', 'mailinator.com', 'hotmail.com'];
    const enteredDomain = email.split('@')[1];
    if (!allowedDomains.includes(enteredDomain)) {
      toast.error('InValid email');
      return;
    }
    setIsDisabled(true);
    setLoading(true);

    let response = await signUp(email, password, passwordConfirm, fname, lname, referrerCode);
    if (response.error) {
      toast.error(response.message);
      setIsDisabled(false);
      setLoading(false);
    } else if (response.success) {
      setIsDisabled(true);
      setLoading(false);
      toast.success(response.message);
    }
  };

  const handleActivate = async () => {
    let response = await activateAccount(email, code);
    if (response.error) {
      toast.error(response.message);
    } else if (response.success) {
      setLoggedIn(true);
      navigate('/home');
      toast.success(response.message);
    }
  };

  const isRegisterDisabled = () => {
    return (fname === "" || lname === "" || email === "" || password === "" || passwordConfirm === "")
  }

  return (
    <div className="auth-container">
      <div className="logo">
        <img src={Logo} />
      </div>
      <div className='signup'>
        <h3>Create an Account</h3>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            name="firstname"
            id="firstname"
            value={fname}
            disabled={isDisabled}
            onChange={(e) => onChange(e.target.value, setFname)}
            placeholder="Your First Name"
          />
          <Input
            type="text"
            name="lastname"
            id="lastname"
            value={lname}
            disabled={isDisabled}
            onChange={(e) => onChange(e.target.value, setLname)}
            placeholder="Your Last Name"
          />
          <Input
            type="email"
            name="email"
            id="email"
            value={email}
            disabled={isDisabled}
            onChange={(e) => onChange(e.target.value, setEmail)}
            placeholder="Email"
          />
          <Input
            type="password"
            name="password"
            id="password"
            value={password}
            disabled={isDisabled}
            onChange={(e) => onChange(e.target.value, setPassword)}
            placeholder="Password"
          />
          {password && !isPasswordValid() && (
            <p className="error-message">
              Password must have at least one capital letter, one symbol, one number, and be at least 7 characters long.
            </p>
          )}
          <Input
            type="password"
            name="confirmpassword"
            id="confirmpassword"
            value={passwordConfirm}
            disabled={isDisabled}
            onChange={(e) => onChange(e.target.value, setPasswordConfirm)}
            placeholder="Confirm password"
          />
          <Input
            type="checkbox"
            className="inline"
            required
            disabled={isDisabled}
          ></Input>
          <label className="inline"><span onClick={toggleTermsPopup} className="terms-link">
            <a> I agree all Terms and conditions </a>
          </span></label>
          <br />
          <Input
            type="checkbox"
            className="inline"
            onClick={() => setHasReferrerCode(!hasReferrerCode)}>
          </Input>
          <label className="inline">Do You have Referrer Code?</label>
          {
            hasReferrerCode && <Input
              type="text"
              name="referrerCode"
              id="referrerCode"
              onChange={(e) => { console.log("ME >>", e.target.value); onChange(e.target.value, setReferrerCode) }}
              placeholder="Referrer Code" />
          }
          {
            isDisabled && !isLoading && <><Input
              type="text"
              name="code"
              id="code"
              className="verification-code"
              onChange={(e) => onChange(e.target.value, setCode)}
              placeholder="Verification Code" />
              <div
                className={`resend-code ${resendDisabled ? "disabled" : ""}`}
                onClick={handleResendVerificationCode}
              >
                {resendDisabled ? `Resend Verification Code (${resendTimer}s)` : "Resend Verification Code"}
              </div>
              <Button disabled={code === ""} onClick={handleActivate}>VERIFY ACCOUNT</Button></>
          }
          {
            !isDisabled && !isLoading && <Button disabled={isRegisterDisabled() || !isPasswordValid()}>Register</Button>
          }
          {
            isLoading && <div className="loader">
              <Spinner className="m-5" color="primary" />
            </div>
          }
        </Form>
        <div className="already">
          Have already a account ? <Link to='/login'>Login here</Link>
        </div>
      </div>
      {showTermsPopup && (
        <div className="terms-popup">
          <div className="terms-popup-content">
            <TermsAndConditions />
            <button onClick={toggleTermsPopup} className="close-popup">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignUp;
